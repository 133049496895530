export type MainDto = {
  // API
  authenticated?: boolean;
  authenticating?: boolean;
  loadingAccountMetadata?: boolean;
  user?: UserDto;
  // UI
  selectedAccount?: AccountDto;
  openModal?: string;
  additionalModalProps?: any;
};

export type UserDto = {
  profile?: ProfileDto;
  // todo: implement below
  favourites?: any;
  recents?: any;
  notifications?: any;
  settings?: any;
};

export type ProfileDto = {
  id: string;
  sessionId: string;
  espUser: boolean;
  particulars: {
    salutation: string;
    firstName: string;
    lastName: string;
    displayName: string;
    avatar: string;
    emailAddress: string;
  };
  accounts: AccountDto[];
};

export type AccountDto = {
  id: string;
  label: string;
  type: number;
};

export type AccountMetadataDto = {
  id: string;
  nodes?: NodeDto[];
};

export type NodeDto = {
  id: string;
  label?: string;
  type?: number;
  city?: string;
  utility?: string;
  unit?: string;
  nodes?: NodeDto[];
};

export type AreaDto = {
  id: string;
  name: string;
  type: string;
  city?: string;
  unit?: string;
  utility?: string;
  utilities: UtilityDto[];
  brushUtilities: UtilityDto[];
  subareas: AreaDto[];
  selected?: boolean;
  parentNames?: string[];
  parentIds?: string[];
};

export type UtilityDto = {
  id: string;
  name: string;
  type: string;
  label: string;
  data: TimeData[];
  // tableData?: TableData;
};

export type MeterDto = {
  name: string;
  type: string;
  data: TimeData[];
  tableData?: TableData;
};

export type AnalyticsDto = {
  result: number;
  dimensions: DimensionsDto[];
};

export type DimensionsDto = {
  id: string;
  utilities: DimensionsUtilityDto[];
};

export type DimensionsUtilityDto = {
  type: number;
  unit: string;
  consumption: AnalyticsConsumptionData[];
};

export type AnalyticsConsumptionData = {
  event: string;
  usage: number;
  lower?: number;
  upper?: number;
  average?: number;
};

export type ComparisonDto = {
  result: number;
  dimensions: ComparisonDimensionsDto[];
};

export type ComparisonDimensionsDto = {
  id: string;
  label: string;
  utilities: ComparisonDimensionsUtilitiesDto[];
};

export type ComparisonDimensionsUtilitiesDto = {
  type: number;
  unit: string;
  usage: ComparisonDimensionsUtilitiesUsageDto;
  change: number;
  total: number;
};

export type ComparisonDimensionsUtilitiesUsageDto = {
  current: number;
  previous: number;
};

export type ChartDto = {
  areaId: string;
  areaName: string;
  type: string;
  name: string;
  utilityType: string;
  utilityLabel: string;
  data: any[];
};

export type TableData = {
  usage: number;
  change: number;
  total: number;
};

export type TimeData = {
  x: number;
  y: number;
};

export interface SearchResult {
  index: number;
  jsx: any;
  area: AreaDto;
}

export interface TableSort {
  index: number;
  direction: string;
}

export interface TriggerDimensionsDto {
  id: string;
  type: number;
  from: string;
  to: string;
}

export interface TriggerCalculatedChannelsDto {
  channelId: string;
  from: string;
  to: string;
}

export interface UpsertContactDto {
  accountId?: string;
  contactId?: string;
  role: number;
  emailAddress: string;
  status?: number;
}

export interface CorrelationReport {
  var_1: string;
  var_2: string;
  corr_value: number;
}

export const friendlyLabels = {
  accountsAddress: 'Addresses',
  accountsSubscription: 'Subscriptions',
  accountsSubscriptions: 'Subscriptions',
  contactsRole: 'Accounts/Contacts',
  channelRules: 'Channel Rules',
  channelJobs: 'Channel Jobs',
  consumptionJobs: 'Consumption Jobs',
  feedsConfigModbus: 'Feeds Config Modbus',
  feedsConfigBacNet: 'Feeds Config BacNet',
  feedsLogs: 'Feeds Logs',
  channelsMappings: 'Channels Mappings',
  weatherReadings: 'Weather Readings',
};

export const typeObj = {
  0: 'unknown',
  1: 'dashboard',
  2: 'group',
  3: 'site',
  4: 'building',
  5: 'load',
  6: 'channel',
  7: 'channelCalc',
};

export const utilityObj = {
  0: null,
  1: 'electricity',
  2: 'water',
  3: 'gas',
  4: 'production',
  5: 'heat',
  6: 'steam',
  7: 'weather',
  8: 'enviromental',
  9: 'ghgEmmisions',
  10: 'other',
};

export const utilityObjRev = {
  electricity: 1,
  water: 2,
  gas: 3,
  production: 4,
  heat: 5,
  steam: 6,
  weather: 7,
  enviromental: 8,
  ghgEmmissions: 9,
  other: 10,
};

export const typeObjRev = {
  unknown: 0,
  dashboard: 1,
  group: 2,
  site: 3,
  building: 4,
  load: 5,
  channel: 6,
};

export interface MappedCsvRow {
  AccountId?: string;
  FeedId?: string;
  SiteName: string;
  SiteId?: string;
  SiteStatus: string;
  SiteCity: string;
  BuildingName: string;
  BuildingId?: string;
  BuildingStatus: string;
  LoadName: string;
  LoadId?: string;
  LoadStatus: string;
  LoadIcpNumber: string;
  LoadMainIncomer: string;
  LoadNegotiatedCapacity: number;
  LoadTransformerCapacity: number;
  LoadMeterSerialNumber: string;
  LoadUtilityType: string;
  LoadDescription: string;
  LoadBreakerRating: string;
  LoadModbusId: string;
  ChannelName: string;
  ChannelId?: string;
  ChannelKey: string;
  ChannelStatus: string;
  ChannelUom: string;
  ChannelSummationType: string;
  ChannelValueType: string;
  ChannelIntervalFrequency: string;
  ChannelTimeZone: string;
  ChannelStartDate: string;
  ChannelEnableAlerts: string;
}

export interface MappedLoadCsvRow {
  LoadId?: string;
  BuildingId?: string;
  ParentLoadId?: string;
  Label?: string;
  IcpNumber?: string;
  Description?: string;
  NabersRating?: string;
  TransformerCapacity?: string;
  MainIncomer?: string;
  UtilityType?: string;
  Status?: string;
  ServiceId?: string;
  TypeId?: string;
  FeedId?: string;
}

export interface MappedChannelCsvRow {
  ChannelId: string;
  Label: string;
  ChannelKey: string;
  Type: number;
  SummationType: number;
  ValueTypeEnum: number;
  NatureType: number;
  IntervalFrequency: string;
  IntervalMins: number;
  TimeZone: string;
  Status: number;
  EnableAlerts: boolean;
  UpdatedOn: string;
  LoadId?: string;
  FeedId?: string;
}

export interface CsvResult {
  Site: CsvResultItem;
  Building: CsvResultItem;
  FeedsType: CsvResultItem;
  Feed: CsvResultItem;
  Load: CsvResultItem;
  Channel: CsvResultItem;
}

export interface CsvResultItem {
  new: any[];
  existing: any[];
  duplicate: any[];
  complete: any[];
  error: any[];
}

export interface AnalyticsRequestSingleDto {
  identifiers: string[];
  start: string;
  end: string;
  interval: number;
  listNatureType: number[];
  unit: string;
  utility: number;
  type: number;
  includeBands?: boolean;
}

export interface AnalyticsRequestDto {
  identifier: string[];
  unit: string;
  utility: number;
  start: string;
  end: string;
  type: number;
  valueTypeEnum?: number;
}

export interface AnalyticsGroupedResponseDto {
  id: string;
  utilities: DimensionsUtilityDto[];
}

export type ActivityDetailDto = {
  notificationGroupId: string;
  building: string;
  startDate: string;
  endDate: string;
  dueDate: string;
  summary: string;
  priority: number;
  action: number;
  status: number;
  ignoreReason: string;
  comments: CommentsDto[];
  assignedTo?: {
    contactId: string;
    displayName: string;
  };
  reporter?: {
    contactId: string;
    displayName: string;
  };
  updatedOn: string;
  carbonImpact: number;
  costImpact: number;
  usageImpact: number;
  loads: ActivityDetailLoadDto[];
  loadsForGraph: ActivityDetailLoadDto[];
  result: number;
  // custom addition
  contacts?: ContactDto[];
};

export type NotificationImpact = {
  type: number;
  symbol: string;
  value: number;
};

export type ActivityDetailLoadDto = {
  loadId: string;
  actualUsage: number;
  equivalentCo2: number;
  equivalentCost: number;
  expectedUsage: number;
  isMainIncomer: boolean;
  utilityType: number;
};

export type AnalyticsPridictionData = {
  event: string;
  predictValue: number;
  hi: number;
  lo: number;
  confidence: number;
}

export type CommentsDto = {
  commentId: string;
  contactId: string;
  name: string;
  body: string;
  status: number;
};

export interface ContactDto {
  contactId: string;
  salutation: string;
  first: string;
  last: string;
  displayName: string;
  email: string;
  mobile: string;
  phone: string;
}

export const intervalObj = {
  None: 0,
  Yearly: 1,
  Months: 2,
  Weeks: 3,
  Days: 4,
  Hours: 5,
  HalfHourly: 6,
  Minutes: 7,
  LooseFrequency: 8,
};

export const intervalObjRev = {
  0: 'N/A',
  1: 'Yearly',
  2: 'Monthly',
  3: 'Weekly',
  4: 'Daily',
  5: 'Hourly',
  6: 'Half Hourly',
  7: '15 Minutes',
  8: 'LooseFrequency',
};

export const voltTypes = ['L1V', 'L2V', 'L3V', 'VoltsA', 'VoltsB', 'VoltsC'];

export const ampTypes = ['L1A', 'L1A Max', 'L2A', 'L2A Max', 'L3A', 'L3A Max'];

export const voltAmpTypes = ampTypes.concat(voltTypes);

export const mlQualificationStatusTypes = [
  'TO_BE_REVIEWED',
  'TO_INVESTIGATE_LATER_BY_ESP',
  'DISQUALIFIED_BY_ESP',
  'QUALIFIED_NOT_ESCALATED',
  'QUALIFIED_BY_ESP',
  'UNDER_INVESTIGATION_BY_CUSTOMER',
  'PENDING_ACTION',
  'QUALIFIED_BY_CUSTOMER',
  'DISQUALIFIED_BY_CUSTOMER',
];

export const actionTypes = {
  0: 'Investigate',
  1: 'Repair',
  2: 'Replace',
  3: 'Tell me more',
  4: 'Get a quote',
  5: 'Known Issue',
  6: 'Expected Change',
  7: 'Not Relevant',
};

export const timezones = ['NZST/NZDT', 'UTC +12'];

export const intervalMins = [1, 15, 30, 60, 1440, 10080, 10080, 43920, 0];

export const intervalFrequency = [
  '1 minute',
  '15 minute',
  '30 minute',
  '60 minute',
  'Daily',
  'Weekly Starting Monday',
  'Weekly Starting Sunday',
  'Monthly',
  'LooseFrequency',
];

export const intervalFrequencyToType = {
  '1 minute': intervalObj.Minutes,
  '15 minute': intervalObj.Minutes,
  '30 minute': intervalObj.HalfHourly,
  '60 minute': intervalObj.Hours,
  Daily: intervalObj.Days,
  'Weekly Starting Monday': intervalObj.Weeks,
  'Weekly Starting Sunday': intervalObj.Weeks,
  Monthly: intervalObj.Months,
  LooseFrequency: intervalObj.LooseFrequency,
};

export const industryCodes = [
  null,
  '',
  'Accommodation & Entertainment',
  'Broadcasting Printing and Publishing',
  'Commercial Property - Owners/Managers',
  'Cool-stores',
  'Entertainment Retail',
  'Food and Tobacco Processing',
  'Food & Beverage Production',
  'Health & Education',
  'Industrial & Manufacturing',
  'Misc.',
  'Not for Profit',
  'Public Sector - Central Govt',
  'Public Sector - Health & Education',
  'Public Sector - Local Govt',
  'Retail Property',
  'test',
  'test2',
  'Transport',
];

export const meterTypes = [null, '', 'ESP Meter', 'Existing', 'Not Metered', 'test', 'Virtual Meter'];

export const communicationTypes = [null, '', 'Modbus', 'Pulse', 'Waveflow'];

export const ctType = [null, '', 'ESP Supplied', 'Existing'];

export const ctSupplied = [
  null,
  '',
  '1600:5A, 22.5mm round bore, MBS AS Range CTs - Din Rail, Foot or Bar Mount, Class 1',
  "200/5A Split Core CT's",
  "300/5A Split Core CT's",
  "400/5A Split Core CT's",
  'Flexible Magnelab Three-Phase RopeCT RCS-1800-1000A',
  'Flexible Magnelab Three-Phase RopeCT RCS-1800-1500A',
  'Flexible Magnelab Three-Phase RopeCT RCS-1800-2000A',
  'Flexible Magnelab Three-Phase RopeCT RCS-1800-2500A',
  'Flexible Magnelab Three-Phase RopeCT RCS-1800-800A',
  'Size 1 - Split Core Voltage CTs - 100A (5m twisted lead)',
  'Size 1 - Split Core Voltage CTs - 150A (5m twisted lead)',
  'Size 1 - Split Core Voltage CTs - 50A (5m twisted leads)',
  'Size 2 - Split Core Voltage CTs - 100A (5m twisted lead)',
  'Size 2 - Split Core Voltage CTs - 200A (5m twisted lead)',
  'Size 2 - Split Core Voltage CTs - 250A (5m twisted lead)',
  'Size 2 - Split Core Voltage CTs - 400A (5m twisted lead)',
  'Size 2 - Split Core Voltage CTs - 600A (5m twisted lead)',
  'Size 3 - Split Core Voltage CTs 400A (5m twisted lead)',
  'Size 3 - Split Core Voltage CTs 600A (5m twisted lead)',
  'Size 3 - Split Core Voltage CTs 800A (5m twisted lead)',
  'Size 4 - Split Core Voltage CTs 1000A (5m twisted lead)',
  'Size 4 - Split Core Voltage CTs 2000A (5m twisted Lead)',
  "Size 4 - Split Core Voltage CT's 800 Amp (5m twisted lead)",
  'Teardrop Magnelab Three-Phase Rope CT RCS-350T-1000A',
  'Teardrop Magnelab Three-Phase Rope CT RCS-350T-2000A',
  'Teardrop Magnelab Three-Phase Rope CT RCS-350T-500A',
];

export const metersSupplied = [
  null,
  '',
  '80 amp Single phase whole current meter with modbus',
  'Cat 1 Meter Supply and Install',
  'Cat 2 Meter Supply and Install',
  'Cat 3 Meter Supply and Install',
  'eGauge Pro',
  'EiFlex 10 (Master unit plus 5 slaves)',
  'EiFlex 12 (Master unit plus 6 slaves)',
  'EiFlex 14 (Master unit plus 7 slaves)',
  'EiFlex 16 (Master unit plus 8 slaves)',
  'EiFlex 18 (Master unit plus 9 slaves)',
  'EiFlex 4 (Master Unit plus 2 slaves)',
  'EiFlex 6 (Master unit plus 3 slaves)',
  'EiFlex 8 (Master unit plus 4 slaves)',
  'Kent Water Meter 20mm PSM-T',
  'Kent Water Meter 25mm PSM-T',
  'Kent Water Meter 40mm PSM-T',
  'Kent Water Meter Helix 4000 100mm',
  'Kent Water Meter Helix 4000 80mm',
  'Meter -3 Phase Direct Read kWh Meter 100A M/Bus',
  'Meter LP3KW4C -3 phase kWh meter with pulse output - unbalanced load',
  'Meter LP3KW4M Modbus 3 phase kWh CT meter - unbalanced load',
  'Northern Design 5 amp cube meter',
  'Northern Design Cube 350 (Amp Input)',
  'Northern Design Cube 350 (MV Input)',
  'Northern Design Rail 350 (AMP input)',
  'Northern Design Rail-350 (MV Input)',
  'PSTM 25mm Water Meter',
  'Siemens Mag5000 150mm with M5100',
  'Single phase 100 Amp direct read meter RS485 Modbus',
  'Temperature & Humidity Sensor industrial',
  'Ultraflow U1000 Flow meter',
  'Ultraflow U1000 Heat meter',
  'Woltman Water Meter 50mm',
];

export const uomTypes = [
  null,
  '',
  'Baseline kWh',
  'Baseline m3',
  'Binary',
  'Cans',
  'CDD for 14C',
  'CDD for 16C',
  'CDD for 18C',
  'CDD for -5C',
  'Count',
  'Days',
  'Degrees Celsius',
  '$/Gas kWh',
  'Gas kWh',
  'GasNorm m3',
  'GasRaw m3',
  'Generated kVAh',
  'Generated kVArh',
  'Generated kWh',
  'GJ',
  'HDD for 18C',
  'Hours',
  'Hz',
  'Joules',
  'kg/kWh',
  'kgs',
  'kVa',
  'kVA',
  'kVAh',
  'kVAr',
  'kVArh',
  'kW',
  'kWh',
  '$/kWh',
  'L1A',
  'L1A Max',
  'L1V',
  'L2A',
  'L2A Max',
  'L2V',
  'L3A',
  'L3A Max',
  'L3V',
  'Litres per second',
  'Metres',
  'Minutes',
  'NONE',
  'Occupancy',
  'on/off',
  'Pascals',
  'Power Factor',
  'Predicted kWh',
  'Predicted m3',
  'PUE',
  'Ratio',
  'Reduction Target kWh',
  'Reduction Target m3',
  'Relative Humidity',
  'RH (Relative Humidity)',
  'Square Metres',
  'Thermal kWh',
  'Tonnes',
  'Unknown',
  'VoltsA',
  'VoltsB',
  'VoltsC',
  '$/Water m3',
  'Water m3',
];
// be tricksy
