import React from 'react';
import { useHistory } from 'react-router-dom';
import { List, SimpleListItem } from '@rmwc/list';
import { inIframe } from 'utilities';
import styles from './Drawer.module.scss';

export interface DrawerComponentProps {
  open?: boolean;
}

const links = [
  {
    graphic: 'notifications',
    text: 'Notifications',
    metaIcon: 'chevron_right',
    url: '/app/list/notifications',
  },
  { graphic: 'face', text: 'Contacts/Users', metaIcon: 'chevron_right', url: '/app/list/contacts' },
  { graphic: 'account_box', text: 'Accounts', metaIcon: 'chevron_right', url: '/app/list/accounts' },
  {
    graphic: 'history',
    text: 'Account Subscriptions',
    metaIcon: 'chevron_right',
    url: '/app/list/accountsSubscription',
  },
  { graphic: 'business', text: 'Sites', metaIcon: 'chevron_right', url: '/app/list/sites' },
  { graphic: 'apartment', text: 'Buildings', metaIcon: 'chevron_right', url: '/app/list/buildings' },
  { graphic: 'settings_input_component', text: 'Loads', metaIcon: 'chevron_right', url: '/app/list/loads' },
  { graphic: 'analytics', text: 'Channels', metaIcon: 'chevron_right', url: '/app/list/channels' },
  { graphic: 'insights', text: 'Feeds', metaIcon: 'chevron_right', url: '/app/list/feeds' },
  {
    graphic: 'stream',
    text: 'Feeds Config Modbus',
    metaIcon: 'chevron_right',
    url: '/app/list/feedsConfigModbus',
  },
  {
    graphic: 'sensors',
    text: 'Feeds Config BacNet',
    metaIcon: 'chevron_right',
    url: '/app/list/feedsConfigBacNet',
  },
  { graphic: 'folder', text: 'Projects', metaIcon: 'chevron_right', url: '/app/list/projects' },
  { graphic: 'task', text: 'Jobs', metaIcon: 'chevron_right', url: '/app/list/consumptionJobs' },
  { graphic: 'report', text: 'Reports', metaIcon: 'chevron_right', url: '/app/list/reports' },
  { graphic: 'dashboard', text: 'ESP View', metaIcon: 'chevron_right', url: '/app/list/dashboards' },
  { graphic: 'history', text: 'History', metaIcon: 'chevron_right', url: '/app/list/history' },
  { graphic: 'air', text: 'Weather', metaIcon: 'chevron_right', url: '/app/list/weather' },
];

export const DrawerComponent: React.FC<DrawerComponentProps> = ({ open }) => {
  const isInIframe = inIframe();
  const history = useHistory();
  const splicedPath = window.location.pathname.split('/');
  let path = window.location.pathname;
  if (splicedPath.length === 5) {
    splicedPath.splice(splicedPath.length - 1, 1);
    path = splicedPath.join('/');
  }
  return (
    <div className={`${styles.drawer} ${!open ? styles.closed : styles.open} ${isInIframe ? styles.inIframe : null}`}>
      <div className={styles.drawerContent}>
        <List
          onAction={(evt) => {
            const url = links[evt.detail.index].url;
            history.push(url);
          }}>
          {links.map((x) => {
            let isSelected = false;
            if (x.url === path) {
              isSelected = true;
            } else if (x.url.replace('list', 'detail') === path) {
              isSelected = true;
            }
            return (
              <SimpleListItem key={x.text} {...x} selected={isSelected} className={isSelected ? styles.active : null} />
            );
          })}
        </List>
      </div>
    </div>
  );
};

export default DrawerComponent;
