import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { StatusEnum, unitToSummationType, ValueTypeEnum, SummationTypeEnum } from './enum';
import { intervalFrequency, intervalMins, intervalFrequencyToType } from './interfaces';
import { AreaDto } from 'utilities';
import { useQuery } from '@apollo/client';

export const numberWithCommas = (x: number) => {
  var parts = x.toString().split('.');
  // eslint-disable-next-line
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
};

export const toTitleCase = (str: string) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const uppercaseFirstLetter = (str: string) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1);
  });
};

export const capitalize = (str = '') => str.charAt(0).toUpperCase() + str.substring(1);

export const groupBy = <T, K extends keyof any>(list: T[], getKey: (item: T) => K) =>
  list.reduce((previous, currentItem) => {
    const group = getKey(currentItem);
    if (!previous[group]) previous[group] = [];
    previous[group].push(currentItem);
    return previous;
  }, {} as Record<K, T[]>);

export const getParameterByName = (name: string) => {
  const theUrl = window.location.href;
  // eslint-disable-next-line
  const theName = name.replace(/[\[\]]/g, '\\$&');
  // eslint-disable-next-line
  const regex = new RegExp('[?&]' + theName + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(theUrl);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const convertQueryStringToJSON = () => {
  var pairs = window.location.search.slice(1).split('&');

  var result = {};
  pairs.forEach(function (pair) {
    const newPair = pair.split('=');
    result[newPair[0]] = decodeURIComponent(newPair[1] || '');
  });
  return JSON.parse(JSON.stringify(result));
};

export const convertOrderDotNotationToObject = (field: string, direction: string) => {
  let orderBy = {};
  if (field) {
    var data = [{ key: field, value: direction }];
    orderBy = data.reduce(function (r, o) {
      var path = (o.key as string).split('.'),
        last = path.pop();

      path.reduce(function (p, k) {
        return (p[k] = p[k] || {});
      }, r)[last] = o.value;
      return r;
    }, {});
  }
  return orderBy;
};

export const getInitials = (name: string) => {
  const initials = name
    ? name
        .split(' ')
        .map((n) => n[0])
        .join('')
    : '';
  return initials;
};

export const mapChannelOptionsToChannels = (
  channelOptions: any,
  namePrefix: string,
  LoadId: string,
  Feed?: {
    FeedId?: string;
    Label?: string;
    FeedsType?: string;
  }
) => {
  const newChannels = channelOptions.map((x) => {
    const channelName = `${namePrefix} ${x.FullData?.Description}`;
    const uom = x.FullData?.Value || '';
    const sumType = unitToSummationType[uom];

    const newChannel = {
      ChannelId: uuidv4(),
      LoadId: LoadId,
      ChannelKey: x.ChannelKey ? x.ChannelKey : uniqueChannelKey(),
      Label: channelName,
      UnitOfMeasure: x.FullData?.Value,
      IntervalFrequency: '15 minute',
      IntervalMins: 15,
      TimeZone: 'NZST/NZDT',
      Status: StatusEnum.Active,
      FeedId: Feed?.FeedId,
      EnableAlerts: x.FullData?.Label.indexOf('kWh') > -1 ? true : false,
      StartDate: moment.utc().format('YYYY-MM-DDTHH:mm:00'),
      CreatedOn: moment.utc().format('YYYY-MM-DDTHH:mm:00'),
      UpdatedOn: moment.utc().format('YYYY-MM-DDTHH:mm:00'),
      ValueTypeEnum: ValueTypeEnum.ActualForInterval,
      SummationType: sumType && SummationTypeEnum[sumType] ? SummationTypeEnum[sumType] : 1,
    };
    return newChannel;
  });
  return newChannels;
};

export const mapChannelsToFeedsConfigModbusChannels = (channels: any) => {
  return channels.map((channel) => {
    const paramSplit = channel.Parameter?.split('/') || [];
    let deviceAddress = paramSplit.length > 0 ? Number(paramSplit[0]) : 0;
    let registerAddress = paramSplit.length > 1 ? Number(paramSplit[1]) : 0;
    if (isNaN(deviceAddress)) {
      deviceAddress = 0;
    }
    if (isNaN(registerAddress)) {
      registerAddress = 0;
    }
    const frequency = channel.IntervalFrequency || '15 minute';
    const frequencyIdx = intervalFrequency.indexOf(frequency);
    const frequencyVal = intervalMins[frequencyIdx > -1 ? frequencyIdx : 1];
    const frequencySec = frequencyVal * 60;
    return {
      ChannelId: channel.ChannelId,
      FeedId: channel.FeedId || channel.Feed?.FeedId,
      DeviceAddress: deviceAddress,
      ReadFrequencySeconds: frequencySec || 15 * 60,
      RegisterAddress: registerAddress,
      RegisterCount: 1,
      RegisterType: 0,
      RtuBaudRate: 9600,
      RtuDataBits: 8,
      RtuParity: 'none',
      RtuSerialPort: 'dev/ttyAMA0',
      RtuStopBits: 'One',
      ScalingFactor: 0,
      TcpIpAddress: null,
      TcpPort: null,
      Status: 1,
      CalculateDelta: false,
      // some to be false
      IsBigEndian: true,
      CreatedOn: moment.utc().format('YYYY-MM-DDTHH:mm:00'),
      UpdatedOn: moment.utc().format('YYYY-MM-DDTHH:mm:00'),
    };
  });
};

export const toCamel = (o: any) => {
  var newO, origKey, newKey, value;
  if (o instanceof Array) {
    return o.map(function (value) {
      if (typeof value === 'object') {
        value = toCamel(value);
      }
      return value;
    });
  } else {
    newO = {};
    for (origKey in o) {
      if (o.hasOwnProperty(origKey)) {
        newKey = (origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey).toString();
        value = o[origKey];
        if (value instanceof Array || (value !== null && value.constructor === Object)) {
          value = toCamel(value);
        }
        newO[newKey] = value;
      }
    }
  }
  return newO;
};

export const uniqueChannelKey = () => {
  return Math.random().toString(36).substr(2, 7).toUpperCase() + Date.now() + 'ESP';
};

export const ObjByString = (o: any, s: string) => {
  s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  s = s.replace(/^\./, ''); // strip a leading dot
  var a = s.split('.');
  for (var i = 0, n = a.length; i < n; ++i) {
    var k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
};

export const setCookie = (cname: string, cvalue: string) => {
  const d = new Date();
  d.setTime(d.getTime() + 60 * 60 * 1000);
  let expires = 'expires=' + d.toUTCString();
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
};

export const getCookie = (cname: string) => {
  let name = cname + '=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const inIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

export const getLowestIntervalFromLoads = (loads: any) => {
  const intervals = loads.flatMap((x) => x.Channels.flatMap((y) => y.IntervalFrequency));
  let lowestIntervalIdx = 0;
  intervals.forEach((x) => {
    const idx = intervalFrequency.indexOf(x);
    if (idx > lowestIntervalIdx) {
      lowestIntervalIdx = idx;
    }
  });
  const frequency = intervalFrequency[lowestIntervalIdx];
  return frequency;
};

export const useImperativeQuery = (query) => {
  const { refetch } = useQuery(query, { skip: true });

  const imperativelyCallQuery = (variables) => {
    return refetch(variables);
  };

  return imperativelyCallQuery;
};
